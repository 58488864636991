<script>

import CartIcon from "@/assets/images/retail/cart-management.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DashboardIcon from "@/assets/images/partners/dashboard.svg";
import PartnersIcon from "@/assets/images/partners/partners.svg";
import ProductsIcon from "@/assets/images/partners/products.svg";
import ConfigurationIcon from "@/assets/images/partners/configuration.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  name: "B2bTabs",
  components: {AddIcon, SvgIcon, CartIcon},
  methods: {
    checkProductEnable() {
      if (this.b2bConfig && this.b2bConfig.enable_specific_products) {
        const newTab = {
          name: "Products",
          icon: ProductsIcon,
          path: ["/b2b/products"],
          permission: this.$modules.b2b.dashboard.slug,
        };

        this.tabs.splice(2, 0, newTab);
      }
    },
    gotoPage(path) {
      if (this.$route.path === path) {
        return;
      }
      this.$router.push(path);
    },
    addEntity() {
      this.$emit("addEntity")
    }
  },
  mounted: function () {
    if (!this.$store.getters.configurationStatus("B2B")) {
      this.$store.dispatch("loadConfigurations", "B2B").then(() => {
        this.checkProductEnable();
      });
    } else {
      this.checkProductEnable();
    }
  },
  computed: {
    b2bConfig() {
      return this.$store.getters.getB2bConfig;
    },
  },
  data() {
    return {
      tabs: [
        {
          name: "Dashboard",
          icon: DashboardIcon,
          path: ["/b2b"],
          permission: this.$modules.b2b.dashboard.slug,
        },
        {
          name: "Partners",
          icon: PartnersIcon,
          path: ["/b2b/partners"],
          permission: this.$modules.b2b.partner.slug,
        },
        {
          name: "Configurations",
          icon: ConfigurationIcon,
          path: ["/b2b/configurations"],
          permission: this.$modules.b2b.partner.slug,
        },
      ],
    }
  },
}
</script>

<template>
  <div class="d-flex justify-space-between align-center mb-8">
    <div class="d-flex p-4 rounded bg-white bordered w-fit tabs">
      <template v-for="(tab) in tabs">
        <div
            v-if="checkReadPermission(tab.permission)"
            v-bind:key="tab.name"
            class="nv_item d-flex pointer tab"
            @click="gotoPage(tab.path[0])"
        >
          <SvgIcon
              :class="{
                    'qp-tab-nav-is-active':tab.path.includes($route.path),
                    'qp-tab-nav':!tab.path.includes($route.path)
                  }"
              :text="tab.name"
              class="text-sm text-thin-gray"
          >
            <template v-slot:icon>
              <component :is="tab.icon" :component="tab.icon"/>
            </template>
          </SvgIcon>
        </div>
      </template>
    </div>
    <v-btn
        v-if="tabs[1].path.includes($route.path) && checkWritePermission($modules.b2b.partner.slug)"
        class="bg-blue text-capitalize"
        dark
        height="48"
        @click="addEntity"
    >
      <AddIcon class="mx-2"/>
      Add Partner
    </v-btn>
  </div>

</template>

<style scoped>

</style>